import React, {useEffect, useState} from "react";
import "./Agenda.scss";
import {formatLongDatum, formatM2H, formatShortDatum} from "../../../utils/util";
import Onderhoud from "../../OnderhoudOverview/Onderhoud/Onderhoud";
import StyledSnackbar from "../../../components/StyledSnackbar/StyledSnackbar";
import Tooltip from "@mui/material/Tooltip";
import IconButton from '@mui/material/IconButton';
import BuildIcon from '@mui/icons-material/Build';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import TextField from "@mui/material/TextField";

const Agenda = (({initialAgenda, context, selectRow, requestOpenRow, saveChanges, index}) => {

    const [agenda, setAgenda] = useState(initialAgenda);
    const [werkbareTijd, setWerkbareTijd] = useState(initialAgenda.werkbareTijd);
    const [viewDetails, setViewDetails] = useState(false);
    const [isInEditMode, setIsInEditMode] = useState(false);
    const [message, setMessage] = useState({});

    const isErIetsTeZien = agenda.onderhoud.length > 0;

    useEffect(() => {
        const listener = event => {
            if (isInEditMode && (event.code === "Enter" || event.code === "NumpadEnter")) {
                saveWerkbareTijd()
            }
        };
        document.addEventListener("keydown", listener);
        return () => {
            document.removeEventListener("keydown", listener);
        };
    });

    useEffect(() => {
        const listener = event => {
            if (event.code === "Escape" && isInEditMode) {
                cancelChanges()
            }
        };
        document.addEventListener("keydown", listener);
        return () => {
            document.removeEventListener("keydown", listener);
        };
    });

    function toggleViewDetails() {
        if (isErIetsTeZien) setViewDetails(!viewDetails);
        else setMessage({value: "Hier is niets te zien ...", type: 'warning'});
    }

    const toggleEditMode = () => {
        setIsInEditMode(!isInEditMode);
        // if (requestOpenRow(isInEditMode ? 'close' : 'open')) setIsInEditMode(!isInEditMode);
    };

    const cancelChanges = () => {
        toggleEditMode();
        setWerkbareTijd(agenda.werkbareTijd);
    };

    const saveWerkbareTijd = () => {
        setAgenda({
            ...agenda,
            werkbareTijd: werkbareTijd
        })
        toggleEditMode();
        saveChanges(agenda.datum, werkbareTijd)
    };


    function beschikbareTijd() {
        const datum = new Date(agenda.datum);
        datum.setHours(23, 59, 59, 999);
        if (datum < (Date.now() + 1)) return '-';
        else return agenda.werkbareTijd - agenda.geplandeTijd
    }

    function colorise() {
        const ratio = 25 + beschikbareTijd() / 4;
        return Math.max(ratio, 20);
    }

    function lighten() {
        return 80 - 5 * (index % 2)
    }


    return (<React.Fragment>
        {context !== "select" && <tr className={"Agenda onClick"} key={index * 1000}>
            <td onClick={toggleEditMode} className={"datum"}>{formatLongDatum(agenda.datum)}</td>
            {!isInEditMode &&
                <td onClick={toggleEditMode}>{agenda.werkbareTijd !== 0 ? formatM2H(agenda.werkbareTijd) : ''}</td>}
            {isInEditMode && <td className={"EditMode alignCenter"}>
                <TextField
                    variant={"standard"}
                    className="variable-details__fullwidth"
                    value={werkbareTijd}
                    onChange={(e) => setWerkbareTijd(e.target.value)}
                    type="text"
                />
            </td>}
            <td onClick={toggleEditMode}>{agenda.geplandeTijd !== 0 ? formatM2H(agenda.geplandeTijd) : ''}</td>
            <td onClick={toggleEditMode}
                style={{background: `hsl(${colorise()},100%, ${lighten()}%)`}}>{beschikbareTijd() !== 0 ? formatM2H(beschikbareTijd()) : ''}</td>
            <td onClick={toggleEditMode}>{agenda.onderhoud.length > 0 ? agenda.onderhoud.length : ''}</td>
            <td className={"alignRight"}>
                <Tooltip title="Wijzig werkbare tijd" placement={"top"}>
                    <IconButton size="small" onClick={toggleEditMode}>
                        <EditIcon fontSize="inherit"/>
                    </IconButton>
                </Tooltip>
            </td>
            {agenda.onderhoud.length > 0 ?
                <td onClick={toggleViewDetails} className={"alignRight bg-white w-20"}>
                    <Tooltip title="Toon onderhoud" placement={"top"}>
                        <IconButton size="small">
                            <BuildIcon fontSize="inherit"/>
                        </IconButton>
                    </Tooltip>
                </td> :
                <td className={"alignRight bg-white w-20"}>&nbsp;</td>}
        </tr>}
        {isInEditMode && <tr className={"EditMode"}>
            <td colSpan={6} className={"alignLeft"}>
                <Tooltip title="Annuleer" placement={"top"}>
                    <IconButton onClick={cancelChanges}>
                        <CloseIcon/>
                    </IconButton>
                </Tooltip>
                <Tooltip title="Bewaar" placement={"top"}>
                    <IconButton onClick={() => saveWerkbareTijd(agenda.datum, werkbareTijd)}>
                        <SaveAltIcon/>
                    </IconButton>
                </Tooltip>
            </td>
        </tr>}
        {context === "select" && <tr id={"selectable-date-"+index} className={"Agenda onClick"} key={index * 1000} onClick={selectRow}>
            <td className={"alignLeft"}>{formatShortDatum(agenda.datum)}</td>
            <td style={{background: `hsl(${colorise()},100%, ${lighten()}%)`}}>{beschikbareTijd() !== 0 ? formatM2H(beschikbareTijd()) : ''}</td>
        </tr>}
        {viewDetails && <React.Fragment>
            <tr className={"Onderhoud OnClick"} onClick={toggleViewDetails} key={index * 1000 + 1}>
                <th>&nbsp;</th>
                <th>Klant</th>
                <th>Omschrijving</th>
                <th>Fiets</th>
                <th>Tijd</th>
            </tr>
            {agenda.onderhoud.map(row => <Onderhoud context={"agenda"} onderhoud={row} key={row.id}/>)}
        </React.Fragment>}
        <StyledSnackbar message={message.value} type={message.type} resetMessage={() => setMessage({})}/>
    </React.Fragment>);
});

export default Agenda;


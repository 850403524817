import React, { useEffect, useMemo, useState } from "react";
import "./OnderhoudRegel.scss";
import {
    formatOmschrijving,
    formatOmschrijvingMetPrijs,
    formatCurrency,
    formatM2H,
    truncateText,
    totaal
} from "../../../../utils/util";
import StyledSnackbar from "../../../../components/StyledSnackbar/StyledSnackbar";
import usePrivateApi from "../../../../hooks/usePrivateApi";
import TextField from "@mui/material/TextField";
import SearchSelect from "../../../../components/SearchSelect/SearchSelect";
import Tooltip from "@mui/material/Tooltip";
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import DeleteIcon from '@mui/icons-material/Delete';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import EditIcon from "@mui/icons-material/Edit";


const OnderhoudRegel = (({ onderhoudRegel, rowType, requestOpenRow, isEditAllowed, persistRow, moveRow, removeRow }) => {

    const api = usePrivateApi();
    const isArbeid = rowType === "arbeid"
    const isNew = !onderhoudRegel.id

    const initialState = useMemo(() => {
        return {
            ...onderhoudRegel,
            regelSjabloonId: isArbeid ? onderhoudRegel.arbeid.id : onderhoudRegel.materiaal.id,
            totaalPrijs: isArbeid && onderhoudRegel.arbeid.vastBedrag ? formatCurrency(totaal(1, onderhoudRegel.prijs)) :
                isArbeid && !onderhoudRegel.vastBedrag ? formatCurrency(totaal(onderhoudRegel.tijd / 60, onderhoudRegel.prijs)) :
                    formatCurrency(totaal(onderhoudRegel.aantal, onderhoudRegel.prijs)),
            artikelnummer: !isArbeid ? onderhoudRegel.materiaal.artikelnummer : "",
            btw: isArbeid ? onderhoudRegel.arbeid.btw : onderhoudRegel.materiaal.btw,
            vastBedrag: isArbeid ? onderhoudRegel.arbeid.vastBedrag : false,
        }
    }, [onderhoudRegel, isArbeid]);

    const [formFieldValues, setFormFieldValues] = useState(initialState);
    const [isInEditMode, setIsInEditMode] = useState();
    const [isRowSelected, setIsRowSelected] = useState(false);
    const [message, setMessage] = useState({});

    useEffect(() => {
        setFormFieldValues(initialState);
    }, [initialState]);

    useEffect(() => {
        setIsInEditMode(onderhoudRegel.editMode)
    }, [onderhoudRegel])

    useEffect(() => {
        const listener = event => {
            if (event.code === "Escape" && isInEditMode) {
                cancelChanges()
            }
        };
        document.addEventListener("keydown", listener);
        return () => {
            document.removeEventListener("keydown", listener);
        };
    });

    const changeOnderhoudRegelState = (key, value) => {
        const aantal = key === "aantal" ? value : formFieldValues.aantal;
        const prijs = key === "prijs" ? value : formFieldValues.prijs;
        const tijd = key === "tijd" ? value : formFieldValues.tijd;
        setFormFieldValues({
            ...formFieldValues,
            [key]: value,
            totaalPrijs: isArbeid && formFieldValues.vastBedrag ? formatCurrency(totaal(1, prijs)) :
                isArbeid && !formFieldValues.vastBedrag ? formatCurrency(totaal(tijd / 60, prijs)) :
                    formatCurrency(totaal(aantal, prijs)),

        });
    };

    const selectRegel = (regel) => {
        setIsRowSelected(true)
        setFormFieldValues({
            ...formFieldValues,
            omschrijving: formatOmschrijving(rowType, regel),
            prijs: regel.prijs,
            tijd: regel.tijd,
            regelSjabloonId: regel.id,
            btw: regel.btw,
            vastBedrag: isArbeid ? regel.vastBedrag : false,
            artikelnummer: regel.artikelnummer,
            totaalPrijs: isArbeid && regel.vastBedrag ? formatCurrency(totaal(1, regel.prijs)) :
                isArbeid && !regel.vastBedrag ? formatCurrency(totaal(regel.tijd / 60, regel.prijs)) :
                    formatCurrency(totaal(formFieldValues.aantal, regel.prijs)),
        });
    };

    const saveRow = (regel) => {
        if (isNew && !!regel.saveButton && !isRowSelected) {
            setMessage({ value: "Kiezen is een kunst", type: 'warning' })
            return
        }
        const saveUnselectedRow = !regel.saveButton && isNew && !isRowSelected
        if (!saveUnselectedRow && formFieldValues.omschrijving === "") {
            setMessage({ value: "Omschrijving mag niet leeg zijn", type: 'warning' })
            return
        }

        const row = {
            ...formFieldValues,
            omschrijving: saveUnselectedRow ? formatOmschrijving(rowType, regel) : formFieldValues.omschrijving,
            prijs: saveUnselectedRow ? regel.prijs : formFieldValues.prijs,
            tijd: saveUnselectedRow ? (regel.tijd ? regel.tijd : 0.00) : formFieldValues.tijd,
            vastBedrag: saveUnselectedRow ? (isArbeid ? regel.vastBedrag : false) : formFieldValues.vastBedrag,
            regelSjabloonId: saveUnselectedRow ? regel.id : formFieldValues.regelSjabloonId,
            totaalPrijs: isArbeid && onderhoudRegel.vastBedrag ? formatCurrency(totaal(1, saveUnselectedRow ? regel.prijs : formFieldValues.prijs)) :
                isArbeid && !onderhoudRegel.vastBedrag ? formatCurrency(totaal(onderhoudRegel.tijd / 60, saveUnselectedRow ? regel.prijs : formFieldValues.prijs)) :
                    formatCurrency(totaal(onderhoudRegel.aantal, saveUnselectedRow ? regel.prijs : formFieldValues.prijs)),

        };
        toggleEditMode();
        persistRow(rowType, row)
    };

    const cancelChanges = () => {
        setFormFieldValues(initialState);
        toggleEditMode();
        if (onderhoudRegel.id === null) removeRow(rowType, onderhoudRegel.id)
    };

    const clearRegel = () => {
        setIsRowSelected(false)
        setFormFieldValues({
            ...initialState,
            omschrijving: ''
        });
    };

    const deleteRow = async () => {
        if (isInEditMode) toggleEditMode()
        removeRow(rowType, onderhoudRegel.id)
    };

    const moveRowUp = async () => {
        moveRow(rowType, onderhoudRegel.id, "up");
    };

    const moveRowDown = async () => {
        moveRow(rowType, onderhoudRegel.id, "down");
    };

    const toggleEditMode = () => {
        const allowed = requestOpenRow(isInEditMode ? 'close' : 'open');
        if (allowed) {
            setIsInEditMode(!isInEditMode);
        }
    };

    const formatText = (row) => formatOmschrijving(rowType, row);
    const formatSearch = (row) => formatOmschrijvingMetPrijs(rowType, row);

    if (!isInEditMode) {
        return (<tr className={isEditAllowed ? "OnderhoudRegel OnClick" : "OnderhoudRegel"}>
            {!isArbeid && <td onClick={toggleEditMode}>{onderhoudRegel.aantal}</td>}
            <td onClick={toggleEditMode} colSpan={isArbeid ? 3 : 2}>{truncateText(onderhoudRegel.omschrijving)}</td>
            {!isArbeid &&
                <td onClick={toggleEditMode} className={"alignRight"}>{onderhoudRegel.materiaal.artikelnummer}</td>}
            {isArbeid && 
                <td onClick={toggleEditMode} className={"alignRight"}>{formatM2H(onderhoudRegel.tijd)}</td>}
            {isArbeid && onderhoudRegel.vastBedrag &&
                <td onClick={toggleEditMode} className={"alignRight"}>Vast bedrag</td>}
            {isArbeid && !onderhoudRegel.vastBedrag &&
                <td onClick={toggleEditMode} className="prijs">{formatCurrency(onderhoudRegel.prijs)}</td>}
            <td onClick={toggleEditMode} className="prijs">
                {isArbeid && onderhoudRegel.vastBedrag ? formatCurrency(totaal(1, onderhoudRegel.prijs ? onderhoudRegel.prijs : 0.00)) :
                    isArbeid && !onderhoudRegel.vastBedrag ? formatCurrency(totaal(onderhoudRegel.tijd / 60, onderhoudRegel.prijs ? onderhoudRegel.prijs : 0.00)) :
                        formatCurrency(totaal(onderhoudRegel.aantal, onderhoudRegel.prijs ? onderhoudRegel.prijs : 0.00))}</td>
            <td onClick={toggleEditMode} className={"alignRight vtop"}>{formFieldValues.btw ? formFieldValues.btw : ""}</td>
            {isEditAllowed && <>
                <td className={"alignRight w-20"}>
                    <Tooltip title={`Bewerk ${rowType}`} placement={"top"}>
                        <IconButton size="small" onClick={toggleEditMode}>
                            <EditIcon fontSize="inherit" />
                        </IconButton>
                    </Tooltip>
                </td>
                <td className="alignRight w-20 bg-white">
                    <Tooltip title="Naar beneden" placement={"top"}>
                        <IconButton onClick={moveRowDown} size="small">
                            <ArrowDropDownIcon fontSize="inherit" />
                        </IconButton>
                    </Tooltip>
                </td>
                <td className="alignRight w-20 bg-white">
                    <Tooltip title="Naar boven" placement={"top"} size="small">
                        <IconButton onClick={moveRowUp}>
                            <ArrowDropUpIcon fontSize="inherit" />
                        </IconButton>
                    </Tooltip>
                </td>
            </>}
        </tr>)
    } else {
        return (
            <React.Fragment>
                <tr className={"OnderhoudRegel EditMode"}>
                    {!isArbeid && <td className={"ten"}>
                        <TextField
                            variant={"standard"}
                            value={formFieldValues.aantal}
                            onChange={(e) => changeOnderhoudRegelState('aantal', e.target.value)}
                            type="number"
                        />
                    </td>}
                    <td colSpan={isArbeid ? 3 : 2}>
                        <div className={"searchSelect"}>
                            <SearchSelect
                                autoFocus={true}
                                value={formFieldValues.omschrijving}
                                initialKey={onderhoudRegel.omschrijving}
                                dataFetchCall={isArbeid ? api.getAllArbeidSelect : api.getAllMateriaalSelect}
                                selectRow={selectRegel}
                                clearRow={clearRegel}
                                saveRow={saveRow}
                                updateRow={(newOmschrijving) => changeOnderhoudRegelState('omschrijving', newOmschrijving)}
                                formatSearch={formatSearch}
                                formatText={formatText}
                                editOnly={!isNew}
                                placeholder={isNew ? "Zoeken" : "Omschrijving"}
                            />
                        </div>
                    </td>

                    {isArbeid &&
                        <td className={"w-20 alignRight"}>
                            <TextField
                                variant={"standard"}
                                className="alignRight"
                                value={formFieldValues.tijd}
                                onChange={(e) => changeOnderhoudRegelState('tijd', e.target.value)}
                                type="number"
                            />
                        </td>}
                    {!isArbeid &&
                        <td className={"alignRight paddingTop"}>{formFieldValues.artikelnummer}</td>}

                    <td className="w-20">
                        <TextField
                            variant={"standard"}
                            className="prijs"
                            value={formFieldValues.prijs}
                            onChange={(e) => changeOnderhoudRegelState('prijs', e.target.value)}
                            type="number"
                        />
                    </td>
                    <td className="prijs paddingTop">{formatCurrency(formFieldValues.totaalPrijs)}</td>
                    <td className={"alignRight paddingTop vtop"}>{formFieldValues.btw ? formFieldValues.btw : ""}</td>
                    <td >&nbsp;</td>
                    <td className={"w-20 bg-white"}>&nbsp;</td>
                </tr>
                <tr className={"bg-grey"}>
                    <td colSpan={6}>
                        <Tooltip title="Annuleer" placement={"top"}>
                            <IconButton onClick={cancelChanges}>
                                <CloseIcon />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Bewaar" placement={"top"}>
                            <IconButton onClick={() => saveRow({ saveButton: true })}>
                                <SaveAltIcon />
                            </IconButton>
                        </Tooltip>
                    </td>
                    <td className="alignRight">
                        <Tooltip title="Verwijder" placement={"top"}>
                            <IconButton onClick={deleteRow}>
                                <DeleteIcon />
                            </IconButton>
                        </Tooltip>
                    </td>
                    <td>&nbsp;</td>
                </tr>
                <tr>
                    <td colSpan={8}>
                        <StyledSnackbar message={message.value} type={message.type}
                            resetMessage={() => setMessage({})} />
                    </td>
                </tr>
                {/* <tr>
                    <td colSpan={7}>
                        <hr />
                        {"[isArbeid] " + JSON.stringify(isArbeid)}
                        <hr />
                        {"[vastbedrag] " + JSON.stringify(onderhoudRegel?.arbeid?.vastBedrag)}
                        <hr />
                        {"[onderhregel] " + JSON.stringify(onderhoudRegel)}
                        <hr />
                        {"[FF?] " + JSON.stringify(formFieldValues)}
                        <hr />
                        {"[IS?] " + JSON.stringify(initialState)}
                    </td>
                </tr> */}

            </React.Fragment>)
    }

});

export default OnderhoudRegel;

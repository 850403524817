import React, { useEffect, useRef, useState } from "react";
import "./Arbeid.scss";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import DoneOutlinedIcon from '@mui/icons-material/DoneOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import usePrivateApi from "../../../hooks/usePrivateApi";
import EanQRCode from "../../../components/QRCode/EanQRCode";
import EanQRPrintCode from "../../../components/QRCode/EanQRPrintCode";
import { useReactToPrint } from "react-to-print";
import { formatCurrency, formatM2H, truncateText, validateArbeid } from "../../../utils/util";


const Arbeid = (({ initialArbeid, requestOpenRow, isQr, showMessage }) => {

    const api = usePrivateApi();

    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    const [formFieldValues, setFormFieldValues] = useState(initialArbeid);
    const [arbeid, setArbeid] = useState(initialArbeid);
    const [isInEditMode, setIsInEditMode] = useState(false);

    useEffect(() => {
        const listener = event => {
            if (isInEditMode && (event.code === "Enter" || event.code === "NumpadEnter")) {
                saveChanges()
            }
        };
        document.addEventListener("keydown", listener);
        return () => {
            document.removeEventListener("keydown", listener);
        };
    });

    useEffect(() => {
        const listener = event => {
            if (event.code === "Escape" && isInEditMode) {
                cancelChanges()
            }
        };
        document.addEventListener("keydown", listener);
        return () => {
            document.removeEventListener("keydown", listener);
        };
    });

    const toggleEditMode = () => {
        if (requestOpenRow(isInEditMode ? 'close' : 'open'))
            setIsInEditMode(!isInEditMode);
    };

    const changeArbeidState = (key, value) => {
        setFormFieldValues({
            ...formFieldValues,
            [key]: value
        });
    };

    const cancelChanges = () => {
        setFormFieldValues(arbeid);
        toggleEditMode();
    };

    const saveChanges = async () => {
        var validatedArbeid = validateArbeid(formFieldValues)
        if (validatedArbeid.isValid) {
            api.updateArbeid(arbeid.id, validatedArbeid)
                .then(
                    (data) => {
                        toggleEditMode();
                        setArbeid(data);
                        showMessage({ value: 'Arbeid is opgeslagen', type: 'success' });
                    },
                    (e) => showMessage({ value: `Failed to update arbeid: ${e}`, type: 'error' }))
        } else {
            showMessage({ value: 'Arbeid is niet geldig', type: 'error' })
        }
    };

    const fieldNames =
        ["code", "omschrijving", "tijd", "prijs", "btw", "vastBedrag"];

    const columnSpan = (fieldName) => {
        switch (fieldName) {
            case 'omschrijving':
                return 6;
            default:
                return 1;
        }
    }
    const className = (fieldName) => {
        switch (fieldName) {
            case 'code':
                return 'ten alignLeft';
            case 'omschrijving':
                return 'thirty alignLeft';
            default:
                return 'ten alignCenter';
        }
    }

    const content = (fieldName) => {
        switch (fieldName) {
            case 'code':
                return isQr ? <EanQRCode size={20} eanCode={arbeid.code} /> : arbeid[fieldName];
            case 'omschrijving':
                return truncateText(arbeid.omschrijving)
            case 'prijs':
                return arbeid.vastBedrag ? `€ ${formatCurrency(arbeid.prijs)}` : `€ ${formatCurrency(arbeid.prijs * arbeid.tijd / 60)}`
            case 'tijd':
                return formatM2H(arbeid.tijd)
            case 'vastBedrag':
                return arbeid[fieldName] ? <DoneOutlinedIcon /> : <CloseOutlinedIcon />
            default:
                return arbeid[fieldName];
        }
    }

    return (
        <>
            {!isInEditMode && (
                <tr className={"arbeid OnClick"} onClick={isQr ? handlePrint : toggleEditMode}>
                    {fieldNames.map((fieldName, index) => (
                        <td colSpan={columnSpan(fieldName)}
                            className={className(fieldName)}
                            key={fieldName}>
                            {content(fieldName)}</td>
                    ))}
                    <td className={"hideprint"}>
                        <EanQRPrintCode
                            className="eancode"
                            ref={componentRef}
                            isArbeid={true}
                            arbeidMateriaal={arbeid} />
                    </td>
                </tr>
            )}

            {isInEditMode && (
                <React.Fragment>
                    <tr className={"arbeid EditMode"}>
                        {fieldNames.map((fieldName, index) => (
                            <td key={fieldName}
                                className={className(fieldName)}
                                colSpan={columnSpan(fieldName)}>
                                <TextField
                                    variant={"standard"}
                                    value={formFieldValues[fieldName]}
                                    multiline
                                    onChange={(e) => changeArbeidState(fieldName, e.target.value)}
                                    type="text"
                                />
                            </td>
                        ))}
                    </tr>
                    <tr className={"EditMode"}>
                        <td colSpan={fieldNames.length + 5}>
                            <Tooltip title="Annuleer" placement={"top"}>
                                <IconButton onClick={cancelChanges}>
                                    <CloseIcon />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="Bewaar" placement={"top"}>
                                <IconButton onClick={saveChanges}>
                                    <SaveAltIcon />
                                </IconButton>
                            </Tooltip>
                        </td>
                        {/*<td className="alignRight">*/}
                        {/*    <Tooltip title="Verwijder" placement={"top"}>*/}
                        {/*        <IconButton onClick={() => deleteArbeid(arbeid.id)}>*/}
                        {/*            <DeleteIcon/>*/}
                        {/*        </IconButton>*/}
                        {/*    </Tooltip>*/}
                        {/*</td>*/}

                    </tr>
                </React.Fragment>)}
        </>
    );
});

export default Arbeid;

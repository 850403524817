import React, {useEffect, useState} from "react";
import "./OnderhoudRegels.scss";
import OnderhoudRegel from "./OnderhoudRegel/OnderhoudRegel";
import StyledSnackbar from "../../../components/StyledSnackbar/StyledSnackbar";
import {formatCurrency, formatM2H} from "../../../utils/util"
import usePrivateApi from "../../../hooks/usePrivateApi";

import Tooltip from "@mui/material/Tooltip";
import IconButton from '@mui/material/IconButton';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import InvertColorsIcon from '@mui/icons-material/InvertColors';
import {useNavigate} from "react-router-dom";

const OnderhoudRegels = (({
                              onderhoud,
                              rowType,
                              requestOpenRow,
                              isEditAllowed,
                              persistOnderhoudRegel,
                              removeOnderhoudRegel,
                              requestEmptyOnderhoudRegel
                          }) => {
            const api = usePrivateApi();
            const isArbeid = rowType === "arbeid"
            const navigate = useNavigate();

            const [message, setMessage] = useState({});
            const [isLoading, setIsLoading] = useState(false);
            const [onderhoudRegels, setOnderhoudRegels] = useState([]);
            const [totaalRegelsKosten, setTotaalRegelsKosten] = useState();
            const [totaalTijd, setTotaalTijd] = useState();

            useEffect(() => {
                setOnderhoudRegels(isArbeid ? onderhoud.arbeid : onderhoud.materiaal);
                setTotaalRegelsKosten(isArbeid ? onderhoud.totaalArbeidKosten : onderhoud.totaalMateriaalHoogKosten + onderhoud.totaalMateriaalNulKosten)
                setTotaalTijd(onderhoud.totaalTijd);
                setIsLoading(false);
            }, [api, isArbeid, onderhoud])

            const navigateToMateriaalNieuw = () => {
                if (requestOpenRow('open'))
                navigate(`/materiaal/nieuw?returnTo=${onderhoud.id}`);
            }

            const addSmeermiddelenOnderhoudRegel = () => {
                if (isEditAllowed && requestOpenRow('open') && onderhoudRegels) {
                    api.getAllMateriaal("SBS_smeermiddelen")
                        .then(
                            data => {
                                const smeermiddelen = {
                                    "smeermiddelen": true,
                                    "id": null,
                                    "aantal": 1,
                                    "omschrijving": data.content[0].omschrijving,
                                    "prijs": data.content[0].prijs,
                                    "regelSjabloonId": data.content[0].id,
                                    "totaalPrijs": data.content[0].prijs
                                };
                                persistOnderhoudRegel("materiaal", smeermiddelen)
                                requestOpenRow('close')
                            })
                }
            }

            const moveOnderhoudRegel = (rowType, regelId, direction) => {
                if (onderhoud.id) {
                    api.moveOnderhoudRegel(rowType, onderhoud.id, regelId, direction)
                        .then(
                            (data) => {
                                setOnderhoudRegels(isArbeid ? data.arbeid : data.materiaal);
                            });
                }
            }

            return (
                <div className="OnderhoudRegels">
                    {isLoading ? (
                        "Loading..."
                    ) : (
                        <div>

                            <table>
                                <tbody>
                                <tr>
                                    <th colSpan={"7"}>
                                        <h2 className={"capitalize"}>{rowType}
                                            {isEditAllowed &&
                                                <Tooltip title={`Voeg ${rowType}regel toe`} placement="top">
                                                    <IconButton
                                                                onClick={() => requestEmptyOnderhoudRegel(rowType)}>
                                                        <ManageSearchIcon/>
                                                    </IconButton>
                                                </Tooltip>}
                                            {isEditAllowed && rowType === "materiaal" &&
                                                <Tooltip title={"Voeg smeermiddelenregel toe"} placement="top">
                                                    <IconButton
                                                                onClick={() => addSmeermiddelenOnderhoudRegel(rowType)}>
                                                        <InvertColorsIcon/>
                                                    </IconButton>
                                                </Tooltip>}
                                            {isEditAllowed && rowType === "materiaal" &&
                                                <Tooltip title={"Voer nieuw onderdeel op"} placement="top">
                                                    <IconButton
                                                                onClick={() => navigateToMateriaalNieuw()}>
                                                        <AddCircleOutlineIcon/>
                                                    </IconButton>
                                                </Tooltip>}
                                        </h2>
                                        {isArbeid &&
                                            (<p> Totale tijd {formatM2H(totaalTijd)}, totale arbeid kosten
                                                € {formatCurrency(totaalRegelsKosten)}</p>)
                                        }
                                        {!isArbeid &&
                                            (<p>Totale {rowType} kosten € {formatCurrency(totaalRegelsKosten)}</p>)
                                        }
                                    </th>
                                </tr>
                                {onderhoudRegels && onderhoudRegels.length > 0 ?
                                    (<tr>
                                        {!isArbeid && <th>Aantal</th>}
                                        <th className={isArbeid ? "sixty" : "forty"} colSpan={isArbeid ? 3 : 2}>Omschrijving</th>
                                        {!isArbeid && <th className={"alignRight twenty"}>Artikelnr.</th>}
                                        {isArbeid && <th className={"alignRight ten"}>Tijd</th>}
                                        {!isArbeid && <th className={"alignRight ten"}>Prijs/stuk</th>}
                                        {isArbeid && <th className={"alignRight ten"}>Uurloon</th>}
                                        <th className={"alignRight ten"}>Totaal</th>
                                        <th className={"alignRight ten"}>BTW</th>
                                        <th className={"alignRight five"}/>
                                    </tr>) :
                                    (<tr>
                                        <td colSpan={7} className={"Italic"}>Geen {rowType}</td>
                                        <td className={"w-20 bg-white"}>&nbsp;</td>
                                        <td className={"w-20 bg-white"}>&nbsp;</td>
                                    </tr>)}
                                {onderhoudRegels && onderhoudRegels.length > 0 ? onderhoudRegels.map((row, index) =>
                                    (<OnderhoudRegel onderhoudRegel={row}
                                                     rowType={rowType}
                                                     requestOpenRow={requestOpenRow}
                                                     isEditAllowed={isEditAllowed}
                                                     persistRow={persistOnderhoudRegel}
                                                     moveRow={moveOnderhoudRegel}
                                                     removeRow={removeOnderhoudRegel}
                                                     placeholder={`${rowType} zoeken`}
                                                     key={row.id ? row.id : index}/>)) : null}
                                </tbody>
                            </table>
                        </div>
                    )}
                    <hr/>
                    {/* {JSON.stringify(onderhoudRegels)} */}
                    <StyledSnackbar message={message.value} type={message.type} resetMessage={() => setMessage({})}/>
                </div>
            );
        }
    )
;

export default OnderhoudRegels;
